<template>
  <div>
    <div class="mt-24 text-center">
      <h1 class="text-4xl leading-10 font-vollkorn font-extrabold text-emerald-700 text-center">
        Pflegehilfsmittelbox anpassen
      </h1>
    </div>

    <!-- Individuelle Boxen -->
    <div class="mt-8" ref="scrollTarget">
      <h2 class="text-2xl font-semibold text-center text-emerald-700">
        Pflegebox selbst zusammenstellen
      </h2>
      <p class="mt-2 text-center text-emerald-700 font-light text-xl">Passend zu Ihren individuellen Bedürfnissen</p>
      <div v-if="progress == 1">
        <div class="grid grid-cols-2 gap-12 mt-12">
          <ProductList class="col-span-2 lg:col-span-1" />
          <ShoppingCart class="col-span-2 lg:col-span-1" :checkSub="checkSub" />
        </div>
        <div class="flex justify-end mt-4">
          <button
            class="
              inline-flex
              justify-center
              py-2
              px-4
              border border-transparent
              shadow-sm
              text-sm
              font-semibold
              rounded-md
              text-white
              focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500
            "
            :class="{
              'bg-red-300 hover:bg-red-500': !minPrice,
              'bg-green-400 hover:bg-green-500': minPrice,
            }"
            :disabled="!minPrice"
            :event="minPrice ? 'click' : ''"
            @click="checkout"
          >
            speichern
          </button>
        </div>
      </div>
      <div class="flex justify-center mt-4" v-else-if="progress == 2">
        <h2 class="text-2xl leading-10 font-vollkorn font-extrabold text-emerald-700 text-center">Ihr Box wurde erfolgreich geändert!</h2>
      </div>
      <div class="flex justify-center mt-4" v-else>
        <h2 class="text-2xl leading-10 font-vollkorn font-extrabold text--700 text-center">Fehler beim Laden Ihrer Box.</h2>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import ProductList from "./ProductList.vue";
import ShoppingCart from "./ShoppingCart.vue";

UIkit.use(Icons);

export default {
  components: { ProductList, ShoppingCart },
  data: () => ({
    checkSub: false,
    progress: 10,
  }),
  computed: {
    ...mapState({
      products: (state) => state.products.all,
    }),
    ...mapGetters("cart", {
      minPrice: "cartMinPrice",
      cartProducts: "cartProducts",
    }),
    ...mapGetters("user", {
      token: "token",
      data: "data",
    }),
  },
  mounted() {
    if (this.$route.query.act !== undefined) {
      this.$store.dispatch("user/anonlogin", {
        data: this.$route.query.act,
        cb: (ok) => {
          if (!ok) {
            this.progress = 10;
          } else {
            this.progress = 1;
            this.$store.dispatch("cart/getSubscription");
          }
        },
      });
    } else {
        this.progress = 10;
        this.$store.dispatch("user/clearAll");
        this.$store.dispatch("cart/clearCart");
        return;
    }
  },
  methods: {
    ...mapActions("cart", ["addProductToCart", "clearCart"]),
    goto(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;

      window.scrollTo(0, top);
    },
    checkout() {
      let found = 0;
      let count = 0;
      for (let i = 0; i < this.cartProducts.length; ++i) {
        if (this.cartProducts[i].Sub !== undefined) {
          count++;
          if (this.cartProducts[i].SubActive !== undefined) {
            found++;
          }
        }
      }

      this.checkSub = true;
      if (count == found) {
        this.$store.dispatch("cart/checkout", {
          data: this.cartProducts,
          cb: (a) => {
            if (a === true) {
              this.progress = 2;
            } else {
              console.log("NEED ERROR HANDLING 1");
            }
          },
        });
      }
    },
  },
};
</script>

<style>
html {
  scroll-behavior: smooth;
}
</style>
